import { jsx as k } from "react/jsx-runtime";
import { createContext as P, useContext as w, useRef as o, useState as u, useCallback as e, useMemo as h } from "react";
const R = P(null), H = ({
  children: n,
  mobileLayout: l = "default"
}) => {
  const m = o(null), d = o(null), a = o(null), s = o(null), x = o(null), N = o(null), L = o(null), y = o(null), [c, M] = u(null), r = e((t) => {
    console.log(t), a.current = t, M(t);
  }, []), [i, p] = u(null), f = e((t) => {
    s.current = t, p(t);
  }, []), [C, v] = u(null), E = e((t) => {
    y.current = t, v(t);
  }, []), b = h(
    () => ({
      layoutRef: m,
      layoutNavRef: d,
      layoutMainRef: a,
      layoutEntityRef: s,
      layoutEntityHeadRef: x,
      layoutEntityMapRef: N,
      layoutEntityPaneRef: L,
      // callbacks
      layoutMainNode: c,
      layoutMainRefCallback: r,
      layoutEntityNode: i,
      layoutEntityRefCallback: f,
      // custom
      layoutEntityCustomRef: y,
      layoutEntityCustomNode: C,
      layoutEntityCustomNodeRefCallback: E,
      mobileLayout: l
    }),
    [
      C,
      E,
      i,
      f,
      c,
      r,
      l
    ]
  );
  return /* @__PURE__ */ k(R.Provider, { value: b, children: n });
}, S = () => {
  const n = w(R);
  if (!n)
    throw new Error(
      "'useLayoutContext()' must be used within a <LayoutProvider /> component"
    );
  return n;
};
export {
  H as LayoutProvider,
  S as useLayoutContext
};
